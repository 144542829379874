import { DetailedStatisticsStatus } from './generalDetailedStatistics';

export enum TransactionStatus {
    Created = 0,
    Scheduled = 1,
    Sent = 2,
    First = 3,
    Second = 4,
    Third = 5,
    Fourth = 6,
    Fifth = 7,
    CancelledNoBalance = 8,
    CancelledBlacklisted = 9,
    CancelledManual = 10,
    CancelledByPurchase = 11,
    CancelledByDuplication = 12,
    CancelledDueToAbandonedCart = 13,
    CancelledBySimilarShipment = 14,
    InactiveSubTool = 98,
    Error = 99
}

export const transactionStatusByStatisticsStatus = {
    [DetailedStatisticsStatus.InProgress]: [
        TransactionStatus.First,
        TransactionStatus.Second,
        TransactionStatus.Third,
        TransactionStatus.Fourth,
        TransactionStatus.Fifth
    ],
    [DetailedStatisticsStatus.Sent]: [TransactionStatus.Sent],
    [DetailedStatisticsStatus.GeneratedSales]: [],
    [DetailedStatisticsStatus.Rejected]: [
        TransactionStatus.CancelledNoBalance,
        TransactionStatus.CancelledBlacklisted,
        TransactionStatus.CancelledManual,
        TransactionStatus.CancelledByPurchase,
        TransactionStatus.Error
    ]
} as const;

export const statisticsStatusByTransactionStatus = {
    [TransactionStatus.First]: DetailedStatisticsStatus.InProgress,
    [TransactionStatus.Second]: DetailedStatisticsStatus.InProgress,
    [TransactionStatus.Third]: DetailedStatisticsStatus.InProgress,
    [TransactionStatus.Fourth]: DetailedStatisticsStatus.InProgress,
    [TransactionStatus.Fifth]: DetailedStatisticsStatus.InProgress,
    [TransactionStatus.Sent]: DetailedStatisticsStatus.Sent,
    [TransactionStatus.CancelledNoBalance]: DetailedStatisticsStatus.Rejected,
    [TransactionStatus.CancelledBlacklisted]: DetailedStatisticsStatus.Rejected,
    [TransactionStatus.CancelledManual]: DetailedStatisticsStatus.Rejected,
    [TransactionStatus.CancelledByPurchase]: DetailedStatisticsStatus.Rejected,
    [TransactionStatus.Error]: DetailedStatisticsStatus.Rejected
} as const;
