import { useCallback } from 'react';
import { daysToWeeks, hoursToMinutes, minutesToHours, minutesToSeconds, secondsToMinutes, weeksToDays } from 'date-fns';
import _has from 'lodash/has';
import _lowerFirst from 'lodash/lowerFirst';
import { useTranslator } from '@hooks';
import { SubToolIdEnum, SubToolTemplateConfigurationFrequencyRange as FrequencyRange } from '@models';
import { daysToMinutes, minutesToDays } from '@utils';

export const convertSendingPeriodToMinutes = (frequencyRange: FrequencyRange, sendingPeriod: number) => {
    let minutes = 0;
    switch (frequencyRange) {
        case FrequencyRange.Minutes:
            minutes = sendingPeriod;
            break;
        case FrequencyRange.Hours:
            minutes = hoursToMinutes(sendingPeriod);
            break;
        case FrequencyRange.Days:
            minutes = daysToMinutes(sendingPeriod);
            break;
        case FrequencyRange.Weeks:
            minutes = weeksToDays(daysToMinutes(sendingPeriod));
            break;
        case FrequencyRange.Seconds:
            minutes = secondsToMinutes(sendingPeriod);
            break;
    }

    return minutes;
};

export const convertMinutesToSendingPeriod = (frequencyRange: FrequencyRange, sendingPeriod: number) => {
    let period = sendingPeriod;
    switch (frequencyRange) {
        case FrequencyRange.Minutes:
            period = sendingPeriod;
            break;
        case FrequencyRange.Hours:
            period = minutesToHours(sendingPeriod);
            break;
        case FrequencyRange.Days:
            period = minutesToDays(sendingPeriod);
            break;
        case FrequencyRange.Weeks:
            period = daysToWeeks(minutesToDays(sendingPeriod));
            break;
        case FrequencyRange.Seconds:
            period = minutesToSeconds(sendingPeriod);
            break;
    }

    return period;
};

export const useStepFrequency = (globalSubToolId?: SubToolIdEnum) => {
    const {
        subTools: {
            common: {
                templates: { frequencyText: translateFrequencyText }
            }
        },
        enums: { subToolTemplateConfigurationFrequencyRange, singularSubToolTemplateConfigurationFrequencyRange }
    } = useTranslator();

    const getFrequency = useCallback(
        (frequencyInMinutes: number, frequencyRange = FrequencyRange.Minutes, subToolId = globalSubToolId) => {
            const parsedFrequency = convertMinutesToSendingPeriod(frequencyRange, frequencyInMinutes);

            const translatedValue =
                Math.abs(parsedFrequency) > 1
                    ? subToolTemplateConfigurationFrequencyRange
                    : singularSubToolTemplateConfigurationFrequencyRange;

            const frequencyText = translatedValue[FrequencyRange[frequencyRange].toLowerCase()];

            let subToolFrequencyDescription = '';
            const subToolName = _lowerFirst(SubToolIdEnum[subToolId]);

            if (subToolId && _has(translateFrequencyText, subToolName)) {
                subToolFrequencyDescription = translateFrequencyText[subToolName];

                if (subToolId === SubToolIdEnum.Repurchase) {
                    subToolFrequencyDescription =
                        parsedFrequency < 0
                            ? translateFrequencyText[subToolName].before
                            : translateFrequencyText[subToolName].after;
                }
            }

            return {
                frequency: parsedFrequency,
                description: `${Math.abs(parsedFrequency)} ${frequencyText.toLowerCase()}`,
                type: frequencyText.toLowerCase(),
                subToolFrequencyDescription
            };
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalSubToolId]
    );

    return { getFrequency };
};
