import { Address } from '~/models/address';
import { BalanceConsumption } from '~/models/balance';
import { SubscriptionStatusEnum, SubscriptionVersion } from '~/models/subscription';
import { SubscriptionInvoice } from '~/models/subscriptionInvoice';
import { CustomerPlanType, SubscriptionTool } from '~/models/subscriptionTool';
import { ToolTypeEnum } from '~/models/tool';
import axiosClient from './apiService';

type GetBalanceConsumptionAsyncProps = {
    startDate: Date;
    endDate?: Date;
};

export type ContractLooseBalancePayload = {
    subscriptionId: number;
    totalCredits: number;
    toolType: ToolTypeEnum;
    paymentMethodType: number;
    payPalNonce?: string;
    nextInvoiceDate?: string;
    card?: {
        number: string;
        cardholderName: string;
        cvv: string;
        expirationMonth: number;
        expirationYear: number;
        brand: string;
    };
    couponsIds?: Array<number>;
    automaticContracting?: AutomaticContractingLooseBalancePayload;
};

export type PayCurrentSubscriptionPayload = {
    paymentMethodType: number;
    payPalNonce?: string;
    nextInvoiceDate?: Date;
    couponsIds?: Array<number>;
    billingData: {
        name: string;
        documentNumber: string;
        documentType: number;
        address: Address;
    };
    card?: {
        number: string;
        cardholderName: string;
        cvv: string;
        expirationMonth: number;
        expirationYear: number;
        brand?: string;
    };
};

export type CurrentSubscriptionDetailsData = {
    id: number;
    nextInvoiceDate: Date;
    status: SubscriptionStatusEnum;
    version: SubscriptionVersion;
};

export type GetAutomaticContractingLooseBalanceProps = {
    subscriptionId: number;
    subscriptionToolType: CustomerPlanType;
};

export type UpdateAutomaticContractingLooseBalanceParams = {
    subscriptionId: number;
    subscriptionToolId?: number;
    subscriptionToolType: CustomerPlanType;
};

export type AutomaticContractingLooseBalancePayload = {
    isEnable: boolean;
    minBalancePercentage?: number;
    balanceToBeContracted?: number;
    maxTimesToRecontract?: number;
};

export const GetBalanceConsumptionUrl = '/subscription/balance';
const LooseBalanceResourceUrl = '/subscription/loose-balance';
export const AutomaticLooseBalanceResourceUrl = `${LooseBalanceResourceUrl}/automatic`;

export const GetBalanceConsumptionAsync = (params: GetBalanceConsumptionAsyncProps, signal: AbortSignal = null) => {
    return axiosClient.get<Array<BalanceConsumption>>(GetBalanceConsumptionUrl, { params, signal });
};

export const ContractLooseBalanceAsync = (data: ContractLooseBalancePayload) => {
    return axiosClient.post<SubscriptionInvoice>(`${LooseBalanceResourceUrl}/contract`, data);
};

export const GetAutomaticContractingLooseBalanceAsync = (
    params: GetAutomaticContractingLooseBalanceProps,
    signal: AbortSignal = null
) => {
    return axiosClient.get<SubscriptionTool>(AutomaticLooseBalanceResourceUrl, { params, signal });
};

export const UpdateAutomaticContractingLooseBalanceAsync = (
    params: UpdateAutomaticContractingLooseBalanceParams,
    data: AutomaticContractingLooseBalancePayload,
    signal: AbortSignal = null
) => {
    return axiosClient.put<boolean>(AutomaticLooseBalanceResourceUrl, data, { params, signal });
};

export const GetCurrentSubscriptionDetailsAsync = (signal: AbortSignal = null) => {
    return axiosClient.get<CurrentSubscriptionDetailsData>('/subscription/status', { signal });
};

export const PayCurrentSubscription = (data: PayCurrentSubscriptionPayload) => {
    return axiosClient.post<SubscriptionInvoice>('/subscription/pay', data);
};
