import { MouseEventHandler, ReactNode, forwardRef } from 'react';
import { useTheme } from 'styled-components';
import { Loading } from '~/components/elements/loading';
import { DefaultButtonStyled, LinkButtonStyled, ButtonStyledProps, ColoredButtonStyled } from './styles';

export type ButtonProps = Omit<ButtonStyledProps, 'color'> & {
    children?: ReactNode;
    type?: 'submit' | 'button' | 'reset';
    styledAs?: 'button' | 'link';
    onClick?: MouseEventHandler<HTMLButtonElement>;
    onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
    color?: 'default' | 'green' | 'purple' | 'red' | 'deepCerise';
    inLoading?: boolean;
    testId?: string;
    role?: string;
    className?: string;
    id?: string;
    form?: string | undefined;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        { children, color = 'default', styledAs = 'button', type = 'button', inLoading = false, testId, ...args },
        ref
    ) => {
        const { colors } = useTheme();
        const disabled = inLoading || args.disabled;
        const sharedProps = { disabled, 'data-testid': testId, ref };

        let buttonColor = null;
        switch (color) {
            case 'green':
                buttonColor = colors.greenLeaf;
                break;
            case 'purple':
                buttonColor = colors.purple;
                break;
            case 'red':
                buttonColor = colors.sangria;
                break;
            case 'deepCerise':
                buttonColor = colors.deepCerise;
                break;
            default:
                break;
        }

        if (styledAs === 'link') {
            return (
                <LinkButtonStyled {...sharedProps} {...args} color={buttonColor ?? colors.lightBlack}>
                    <Loading inLoading={inLoading} size='small' />
                    {children}
                </LinkButtonStyled>
            );
        }

        if (color === 'default') {
            return (
                <DefaultButtonStyled type={type} {...sharedProps} {...args}>
                    <Loading inLoading={inLoading} size='small' />
                    {children}
                </DefaultButtonStyled>
            );
        }

        return (
            <ColoredButtonStyled
                type={type}
                color={colors.white}
                backgroundColor={buttonColor}
                {...sharedProps}
                {...args}>
                <Loading inLoading={inLoading} size='small' />
                {children}
            </ColoredButtonStyled>
        );
    }
);

Button.displayName = 'Button';
