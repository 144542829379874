import Head from 'next/head';
import { useTranslator } from '@hooks';
import { SignInTemplate } from '~/components/templates';
import { SingInLayoutStyled } from './style';

export const SignInLayout = () => {
    const { signInPage } = useTranslator();

    return (
        <SingInLayoutStyled>
            <Head>
                <title>{String(signInPage.title)}</title>
            </Head>
            <SignInTemplate />
        </SingInLayoutStyled>
    );
};
