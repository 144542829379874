import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Checkbox, Input, InputProps, Button } from '@components';
import { useTranslator } from '@hooks';
import { captureException } from '@sentry/nextjs';
import { FormHandles, SubmitHandler, useField } from '@unform/core';
import { useAuth } from '~/providers/authProvider';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import { LogoStyled, SignInFormStyled, SingInHeaderStyled, FormContentStyled } from './styles';

type SignInData = {
    username: string;
    password: string;
    toRemember: boolean;
};

const RegisteredInput = ({ name, ...props }: InputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [fieldName, registerField]);

    return <Input ref={inputRef} name={fieldName} defaultValue={defaultValue} data-validate={error} {...props} />;
};

export const SignInTemplate = () => {
    const formRef = useRef<FormHandles>(null);
    const { query } = useRouter();
    const { SignIn, RecoverPassword } = useAuth();
    const { signInPage } = useTranslator();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isForgotPassword, setForgotPassword] = useState<boolean>(false);

    const { redirectTo } = query as { redirectTo?: string };

    const handleSignIn: SubmitHandler<SignInData> = (data, { reset }) => {
        setErrorMessage(null);
        setIsLoading(true);

        if (isForgotPassword) {
            RecoverPassword(data.username)
                .then((isSuccess) => {
                    if (isSuccess) {
                        reset();
                        setForgotPassword(false);
                    } else {
                        setErrorMessage('Algo deu errado! Tente novamente');
                    }
                })
                .catch((error) => {
                    if (error instanceof EnviouApiResponseError) {
                        setErrorMessage(error.message);
                        error.code && console.error('Error code', error.code);
                    } else {
                        setErrorMessage('Algo deu errado!');
                        console.error(error);
                    }
                    captureException(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            SignIn(data.username, data.password, redirectTo)
                .then(() => reset())
                .catch((error) => {
                    if (error instanceof EnviouApiResponseError) {
                        setErrorMessage(error.message);
                        error.code && console.error(error.code);
                    } else {
                        console.error(error);
                    }
                    captureException(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <SignInFormStyled ref={formRef} onSubmit={handleSignIn} method='POST'>
            <SingInHeaderStyled>
                <LogoStyled />
                <h1>
                    {isForgotPassword
                        ? String(signInPage.form.message.recoverPassword)
                        : String(signInPage.form.message.accessYourAccount)}
                </h1>

                <p>
                    {String(signInPage.form.message.or)}&ensp;
                    <a href='https://enviou.com.br/cadastro'>{String(signInPage.form.message.createNewAccount)}</a>
                </p>
            </SingInHeaderStyled>

            <p>{errorMessage}</p>

            <FormContentStyled>
                <RegisteredInput
                    type='text'
                    name='username'
                    required
                    placeholder={String(signInPage.form.username)}
                    autoComplete='username'
                    testId='input__username'
                />

                {!isForgotPassword && (
                    <RegisteredInput
                        type='password'
                        name='password'
                        required
                        placeholder={String(signInPage.form.password)}
                        autoComplete='current-password'
                        testId='input__password'
                    />
                )}
            </FormContentStyled>

            {!isForgotPassword && (
                <FormContentStyled>
                    <Checkbox name='toRemember' enableUnform testId='checkbox__remember'>
                        <b>{String(signInPage.form.remember)}</b>
                    </Checkbox>
                </FormContentStyled>
            )}

            <FormContentStyled>
                <a href='#' onClick={() => setForgotPassword((value) => !value)}>
                    {isForgotPassword
                        ? String(signInPage.form.message.accessYourAccount)
                        : String(signInPage.form.forgotPassword)}
                </a>
            </FormContentStyled>

            <Button color='purple' type='submit' inLoading={isLoading}>
                {isForgotPassword ? String(signInPage.form.recoverPassword) : String(signInPage.form.enter)}
            </Button>
        </SignInFormStyled>
    );
};
