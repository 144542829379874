import styled from 'styled-components';
import { ExpandableGroup } from '~/components/elements/expandableGroup';

export const SendingTechnologiesStyled = styled(ExpandableGroup)`
    & > div {
        cursor: default;
        padding: 0 2.4rem;
    }
`;

export const SendingTechnologiesContent = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.lightPurple};
    padding: 2rem 0 2rem 11rem;

    & > div {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }
`;

export const SendingTechnologyStyled = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;

    svg {
        color: ${({ theme }) => theme.colors.purple};
    }

    *:not(:first-child) {
        text-align: center;
    }

    & + div:not(:nth-child(2)) {
        margin-top: 1.6rem;
    }
`;
