import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import { FormGroup, InputFormGroup, SelectFormGroup } from '@components';
import { useFetch, useTranslator } from '@hooks';
import { Address, AddressCountry, AddressState } from '@models';
import { useAddress } from '@providers';
import { captureException } from '@sentry/nextjs';
import { FormHandles, Scope } from '@unform/core';
import { RemoveMask, VALUE_TEN } from '@utils';
import {
    ADDRESS_BY_ZIP_CODE_URL,
    GetAddressByZipCode,
    GetStatesOfBrazil,
    STATES_OF_BRAZIL_URL
} from '~/services/brasilApiService';

type BillingDataFormProps = {
    countries: Array<AddressCountry>;
    enableUnform?: boolean;
    formRef: MutableRefObject<FormHandles>;
    prefixFormScopePath?: string;
    parseAddressToFormHandle: (address: Address) => void;
};

export const BillingDataForm = ({
    countries,
    enableUnform = false,
    parseAddressToFormHandle,
    formRef,
    prefixFormScopePath = ''
}: BillingDataFormProps) => {
    const [zipCode, setZipCode] = useState<string>();
    const { billingData } = useTranslator().checkoutPage.subscriptionData;
    const { setStates, states } = useAddress();
    const zipCodeFieldPath = `${prefixFormScopePath}${enableUnform === true ? '.billingData.' : ''}address.zipCode`;
    const { isLoading: isStatesLoading } = useFetch<Array<AddressState>, string, Error>(
        STATES_OF_BRAZIL_URL,
        async () => (await GetStatesOfBrazil()).data,
        {
            onSuccess: (data) => setStates(data),
            onError: (error) => captureException(error),
            dedupingInterval: minutesToMilliseconds(VALUE_TEN)
        }
    );

    const { data: address } = useFetch(
        zipCode && { url: ADDRESS_BY_ZIP_CODE_URL, zipCode },
        async ({ zipCode: code }) => (await GetAddressByZipCode(code)).data,
        {
            keepPreviousData: true,
            onError: (error) => {
                formRef.current.setFieldError(zipCodeFieldPath, String(billingData.form.zipCode.notFound));
                formRef.current.setFieldValue(zipCodeFieldPath, '');
                captureException(error);
            }
        }
    );

    const handleBlurZipCode = useCallback(
        async (currentZipCode: string) => {
            if (currentZipCode && currentZipCode !== zipCode) {
                setZipCode(RemoveMask(currentZipCode));
                formRef.current.setFieldError(zipCodeFieldPath, null);
            }
        },
        [zipCode, formRef, zipCodeFieldPath]
    );

    useEffect(() => {
        if (address) {
            if (!isStatesLoading && !address.state?.id) {
                address.state = states?.find((state) => state.code === address?.state?.code);
            }

            parseAddressToFormHandle(address);
        }
    }, [isStatesLoading, address, states, parseAddressToFormHandle]);

    return (
        <FormGroup enableUnform={enableUnform} unformScopePath='billingData'>
            <InputFormGroup
                type='text'
                name='corporateName'
                autoComplete='name'
                label={String(billingData.form.name.label)}
                placeholder={String(billingData.form.name.placeholder)}
                flexGrid={{ mobileAndTablet: 12, desktop: 12 }}
                testId='input__billing-data-name'
            />

            <InputFormGroup
                type='text'
                name='cpfCnpj'
                label={String(billingData.form.taxpayerRegistry.label)}
                placeholder={String(billingData.form.taxpayerRegistry.placeholder)}
                mask='taxpayerRegistry'
                flexGrid={{ mobileAndTablet: 12, desktop: 6 }}
                testId='input__billing-data-cpf-cnpj'
            />
            <Scope path='address'>
                <InputFormGroup
                    type='text'
                    name='zipCode'
                    autoComplete='postal-code'
                    placeholder={String(billingData.form.zipCode.placeholder)}
                    label={String(billingData.form.zipCode.label)}
                    mask='zipCode'
                    onBlur={(event) => handleBlurZipCode(event.target.value)}
                    flexGrid={{ mobileAndTablet: 12, desktop: 6 }}
                    testId='input__billing-data-zip-code'
                />

                <InputFormGroup
                    type='text'
                    name='street'
                    containerClassName='street-input'
                    autoComplete='address-line1'
                    placeholder={String(billingData.form.street.placeholder)}
                    label={String(billingData.form.street.label)}
                    flexGrid={{ mobileAndTablet: 12, desktop: 4 }}
                    testId='input__billing-data-street'
                />

                <InputFormGroup
                    type='text'
                    name='number'
                    autoComplete='address-line2'
                    label={String(billingData.form.number.label)}
                    placeholder={String(billingData.form.number.placeholder)}
                    flexGrid={{ mobileAndTablet: 12, desktop: 2 }}
                    testId='input__billing-data-number'
                />

                <InputFormGroup
                    type='text'
                    name='complement'
                    autoComplete='address-line3'
                    label={String(billingData.form.complement.label)}
                    placeholder={String(billingData.form.complement.placeholder)}
                    flexGrid={{ mobileAndTablet: 12, desktop: 3 }}
                    testId='input__billing-data-complement'
                />

                <InputFormGroup
                    type='text'
                    name='neighborhood'
                    autoComplete='address-level3'
                    label={String(billingData.form.neighborhood.label)}
                    placeholder={String(billingData.form.neighborhood.placeholder)}
                    flexGrid={{ mobileAndTablet: 12, desktop: 3 }}
                    testId='input__billing-data-neighborhood'
                />

                <SelectFormGroup
                    name='countryCode'
                    flexGrid={{ mobileAndTablet: 12, desktop: 3 }}
                    label={String(billingData.form.country.label)}
                    placeholder={String(billingData.form.country.placeholder)}
                    testId='dropdown__billing-data-country'
                    options={countries?.map((country) => ({ id: country.code, label: country.name }))}
                />

                <SelectFormGroup
                    name='stateCode'
                    flexGrid={{ mobileAndTablet: 12, desktop: 3 }}
                    label={String(billingData.form.state.label)}
                    placeholder={String(billingData.form.state.placeholder)}
                    testId='dropdown__billing-data-state'
                    options={states?.map((state) => ({ id: state.code, label: state.name }))}
                />

                <InputFormGroup
                    type='text'
                    name='city'
                    autoComplete='address-level2'
                    placeholder={String(billingData.form.city.placeholder)}
                    label={String(billingData.form.city.label)}
                    flexGrid={{ mobileAndTablet: 12, desktop: 6 }}
                    testId='input__billing-data-city'
                />
            </Scope>
        </FormGroup>
    );
};
