import { MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';
import { ValidationError } from 'yup';
import {
    Button,
    SMSTemplateEditor,
    WhatsAppTemplateEditor,
    PushTemplateEditor,
    CustomTriggerConfigCard,
    FrequencySelector
} from '@components';
import { CreateOrUpdateEmailTemplate, TagsAnalyticsCard } from '@containers';
import { ConfirmCancelationDialog, ConfirmDeletionDialog } from '@dialogs';
import {
    convertMinutesToSendingPeriod,
    convertSendingPeriodToMinutes,
    serializeSWRKey,
    subToolsFunctions,
    translateAllTemplateVariable,
    translator,
    useFetch,
    useSubToolPage,
    useTranslator,
    VariableParentKey
} from '@hooks';
import {
    CustomizationVariable,
    MessageSequenceStep,
    MessageSequenceStepCreate,
    MessageSequenceStepRange,
    MessageSequenceStepTemplate,
    MessageSequenceStepTemplatePayload,
    MessageSequenceStepUpdate,
    MessageSequenceType,
    NavigationTriggerRule,
    NavigationTriggerRuleConfiguration,
    RepurchaseProduct,
    SendingTechnologyTypeEnum,
    SubToolIdEnum,
    SubToolsEventTriggers,
    subToolsEventTriggersList,
    SubToolTemplateConfigurationFrequencyRange,
    SubToolTemplateConfigurationTypeEnum,
    ThemeTemplate,
    themeTypeBySubToolId,
    PushActionContent,
    PushNotificationContent
} from '@models';
import { captureException } from '@sentry/nextjs';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import {
    convertTreeToList,
    DEFAULT_LOCALE_BANK_SLIP_REMINDER_TEMPLATE,
    DEFAULT_LOCALE_CART_RECOVERY_TEMPLATE,
    DEFAULT_LOCALE_REPURCHASE_TEMPLATE,
    isDevelopment,
    isInvisible,
    minutesToDays,
    scrollTo,
    translateRoute,
    TreeItem,
    VALUE_TEN
} from '@utils';
import { routesName } from '~/locales/route';
import { Alert, useAlert } from '~/providers/alertProvider';
import { GetMessageSequencesCacheKey, GetThemeTemplateCacheKey } from '~/services/cacheKeys';
import { UpdateCustomTriggerAsync } from '~/services/customTriggerApiService';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import { UpdateMessageSequenceNameAsync } from '~/services/navigationTriggerApiService';
import { CreateRuleName, CreateRulesTree } from '~/services/navigationTriggerService';
import { NEWSLETTER_THEME_DETAILS_URL } from '~/services/newsletterApiService';
import {
    GetPushConfigurationIconUrl,
    GetPushConfigurationIconUrlAsync,
    SavePushContentImage
} from '~/services/pushConfigurationServiceApi';
import {
    CreateMessageSequenceStepAsync,
    DeleteMessageSequenceStepAsync,
    GetSubToolNameById,
    GetWhatsAppTemplatesAsync,
    GetWhatsAppTemplatesUrl,
    UpdateMessageSequenceStepAsync
} from '~/services/subToolApiService';
import { normalizeBaseThemeVariables } from '~/services/templateService';
import { PageTitle } from '~/styles/defaultComponentStyles';
import { SubToolConfigurationTemplateButtonGroupStyled, SubToolConfigurationTemplateStyled } from './styles';
import { EmailEditorFormData, PushEditorFormData, TemplateConfigurationFormData, useValidation } from './validation';

type CreateStepTemplateType = {
    formRef: MutableRefObject<FormHandles>;
    subToolKeyIdentifier: VariableParentKey;
    subToolId: SubToolIdEnum;
    children?: ReactNode;
    smsTemplateVariables: Array<CustomizationVariable>;
    whatsappTemplateVariables: Array<CustomizationVariable>;
    emailTemplateVariables: Array<CustomizationVariable>;
    pushTemplateVariables: Array<CustomizationVariable>;
    messageSequenceStep?: MessageSequenceStep;
    subToolPath: string;
    subToolConfiguratorElement?: ReactNode;
    onSubToolSaved?: (formData: unknown) => Promise<NavigationTriggerRuleConfiguration>;
    product?: RepurchaseProduct;
};

type MessageSequenceStepTemplateReduce = {
    email: MessageSequenceStepTemplate | null;
    sms: MessageSequenceStepTemplate | null;
    whatsapp: MessageSequenceStepTemplate | null;
    push: MessageSequenceStepTemplate | null;
};

type StepConfiguration = {
    name: string;
    interval: number;
    timeUnit: SubToolTemplateConfigurationFrequencyRange;
    frequencyType: SubToolsEventTriggers;
};

export type ActionViewType = 'pushOneButton' | 'pushTwoButtons' | 'click';

export const CreateStepTemplate = ({
    formRef,
    subToolKeyIdentifier,
    subToolId,
    subToolConfiguratorElement,
    smsTemplateVariables,
    whatsappTemplateVariables,
    emailTemplateVariables,
    pushTemplateVariables,
    messageSequenceStep,
    subToolPath,
    onSubToolSaved,
    product
}: CreateStepTemplateType) => {
    const { getSubToolPageData } = useSubToolPage();
    const { subToolKey, translatedSubToolName } = getSubToolPageData(subToolId);

    const subToolsEventTriggers: Array<SubToolsEventTriggers> = subToolsEventTriggersList[subToolId as SubToolIdEnum];

    const handleConfigurationChange = (
        name: string,
        interval: number,
        timeUnit: SubToolTemplateConfigurationFrequencyRange,
        frequencyType: SubToolsEventTriggers
    ) => {
        const newStepConfig = {
            name,
            interval,
            timeUnit,
            frequencyType
        };

        setStepConfig(newStepConfig);
    };

    const sendingTechnologyData = Object.keys(SendingTechnologyTypeEnum).reduce<MessageSequenceStepTemplateReduce>(
        (previousValue, item) => {
            const key = SendingTechnologyTypeEnum[item];
            const templateData = messageSequenceStep?.templates?.find(
                (current) => key === (current?.sendingTechnology as SendingTechnologyTypeEnum)
            );
            const defaultValue: MessageSequenceStepTemplate = {
                sendingTechnology: key,
                isActive: false,
                baseContent: null,
                id: null,
                subject: null,
                whatsAppTemplateId: null
            };

            const value = templateData || defaultValue;

            if (key === SendingTechnologyTypeEnum.Email) {
                const { baseHTML, productHtml, reviewHtml } = normalizeBaseThemeVariables({
                    baseHTML: value.baseContent,
                    productHtml: value.productContent,
                    reviewHtml: value.reviewContent
                });
                value.baseContent = baseHTML;
                value.productContent = productHtml;
                value.reviewContent = reviewHtml;
            }

            switch (key) {
                case SendingTechnologyTypeEnum.Email:
                    return { ...previousValue, email: value };
                case SendingTechnologyTypeEnum.WhatsApp:
                    return { ...previousValue, whatsapp: value };
                case SendingTechnologyTypeEnum.Push:
                    return { ...previousValue, push: value };
                case SendingTechnologyTypeEnum.SMS:
                    return { ...previousValue, sms: value };
                default:
                    return previousValue;
            }
        },
        { sms: null, whatsapp: null, email: null, push: null }
    );

    const {
        buttons,
        dialogs: { template, successes, errors, warnings, questions },
        api: { statusError },
        enums: { navigationTriggerRuleLogicalOperator }
    } = useTranslator();
    const { show, success, error, warning } = useAlert();
    const [erroredFieldName, setErroredFieldName] = useState(null);
    const [themeHasBeenChanged, setThemeHasBeenChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const router = useRouter();
    const { locale, query } = router;
    const { subToolTemplateConfigurationSchema } = useValidation();
    const { cache, mutate } = useSWRConfig();
    const [sendingTechnologies, setSendingTechnology] =
        useState<MessageSequenceStepTemplateReduce>(sendingTechnologyData);
    const timeUnit = messageSequenceStep
        ? (Number(messageSequenceStep.intervalRange) as SubToolTemplateConfigurationFrequencyRange)
        : SubToolTemplateConfigurationFrequencyRange.Minutes;
    const defaultInterval = 15;
    const interval = Math.abs(
        convertMinutesToSendingPeriod(timeUnit, messageSequenceStep?.intervalMinutes || defaultInterval)
    );

    let frequencyType: SubToolsEventTriggers = subToolsEventTriggers[0];
    if (subToolId === SubToolIdEnum.BankSlipReminder) {
        if (messageSequenceStep.frequencyType === SubToolsEventTriggers.AfterAbandonment) {
            frequencyType = SubToolsEventTriggers.BeforeExpiration;
        } else {
            frequencyType = SubToolsEventTriggers.AfterPurchase;
        }
    } else if (subToolId === SubToolIdEnum.Repurchase) {
        if (messageSequenceStep.intervalMinutes > 0) {
            frequencyType = SubToolsEventTriggers.AfterRepurchaseDeadline;
        } else {
            frequencyType = SubToolsEventTriggers.BeforeRepurchaseDeadline;
        }
    }

    const [stepConfig, setStepConfig] = useState<StepConfiguration>({
        name: messageSequenceStep?.name || '',
        interval,
        timeUnit,
        frequencyType
    });

    const redirectToMyAutomationsPage = async () => {
        const automationToolPath = routesName.tool.automationTool;
        const routePath = routesName.subTool.subTool;
        const translatedRoutePath = translateRoute(routePath, locale, { subTool: GetSubToolNameById(subToolId) });

        await router.push({ pathname: automationToolPath }, translatedRoutePath);
    };

    const handleDeleteButton = async () => {
        if (!messageSequenceStep?.id) {
            return;
        }

        try {
            await DeleteMessageSequenceStepAsync(subToolPath, messageSequenceStep.id);
            await mutate(() =>
                serializeSWRKey({
                    url: subToolsFunctions[subToolId as SubToolIdEnum].getMessageSequencesUrl,
                    subToolId,
                    currentPage: 1
                } as GetMessageSequencesCacheKey)
            );

            redirectToMyAutomationsPage();
            success(String(template.deleted.success.title), undefined, { enableOutsideClosed: true });
        } catch (exception) {
            const errorMessage =
                exception instanceof EnviouApiResponseError
                    ? exception.getFirstError('createOrUpdateMessageSequenceStatusError').message
                    : String(statusError.systemErrorStatus.internalError);

            error(errorMessage);
        }
    };

    const handleChangeSendingTechnology = useCallback(
        (value: boolean, sendingTechnologyId: SendingTechnologyTypeEnum) => {
            const updatedData = { ...sendingTechnologies };

            switch (sendingTechnologyId) {
                case SendingTechnologyTypeEnum.Email:
                    updatedData.email.isActive = value;
                    break;
                case SendingTechnologyTypeEnum.WhatsApp:
                    updatedData.whatsapp.isActive = value;
                    break;
                case SendingTechnologyTypeEnum.Push:
                    updatedData.push.isActive = value;
                    break;
                case SendingTechnologyTypeEnum.SMS:
                    updatedData.sms.isActive = value;
                    break;
                default:
                    break;
            }
            formRef.current.setFieldValue('sendingTechnologies', updatedData);
            setSendingTechnology(updatedData);
        },
        [formRef, sendingTechnologies]
    );

    const { data: pushIcon } = useFetch(
        { url: GetPushConfigurationIconUrl },
        async (_, signal) => {
            const response = await GetPushConfigurationIconUrlAsync(signal);
            return response.data;
        },
        { keepPreviousData: true, dedupingInterval: minutesToMilliseconds(VALUE_TEN) }
    );

    const { data: whatsAppTemplates } = useFetch(
        GetWhatsAppTemplatesUrl,
        async () => {
            const templates = await GetWhatsAppTemplatesAsync();
            return templates.data;
        },
        {
            keepPreviousData: true,
            fallbackData: []
        }
    );

    const { sms: smsData, whatsapp: whatsappData, email, push: pushData } = sendingTechnologies;

    const handleSubmitForm: SubmitHandler<
        TemplateConfigurationFormData & { rulesTree: Array<TreeItem<NavigationTriggerRule>> }
    > = async (formData, _, event) => {
        try {
            setIsLoading(true);

            const trackAnalytics = { ...formData?.trackAnalytics };
            Object.keys(trackAnalytics).forEach((key) => {
                if (trackAnalytics[key] === '' || _isNil(trackAnalytics[key])) {
                    delete trackAnalytics[key];
                }
            });

            if (
                formData.templateEmailEditorEnabled &&
                !formData.baseThemeId &&
                !formData?.templateEmailEditor?.baseHTML
            ) {
                warning(String(warnings.themeNotFound.title), String(warnings.themeNotFound.description));
                setIsLoading(false);
                return;
            }

            formRef.current.setErrors({});

            await subToolTemplateConfigurationSchema.validate(formData, {
                abortEarly: false,
                context: {
                    subToolId,
                    templateEmailEditor: { enabled: formData.templateEmailEditorEnabled },
                    pushEnabled: formData.pushEnabled
                }
            });

            const templateConfiguration: Array<MessageSequenceStepTemplatePayload> = [];

            let baseTheme: ThemeTemplate | undefined;
            let saveThemeContent =
                themeHasBeenChanged ||
                !formData?.baseThemeId ||
                subToolId === SubToolIdEnum.CartRecovery ||
                subToolId === SubToolIdEnum.BankSlipReminder;
            const { baseThemeId, templateEmailEditor, smsMessage, whatsappMessage, pushNotification } = formData;

            if (baseThemeId) {
                baseTheme = cache.get(
                    serializeSWRKey<GetThemeTemplateCacheKey>({
                        url: NEWSLETTER_THEME_DETAILS_URL,
                        themeId: baseThemeId,
                        themeType: themeTypeBySubToolId[subToolId]
                    })
                )?.data;

                if (baseTheme) {
                    email.baseThemeId = baseTheme.id;
                    if (
                        !_isEqual(baseTheme.htmlBase, templateEmailEditor.baseHTML) ||
                        !_isEqual(baseTheme.htmlItems, templateEmailEditor?.productHTML) ||
                        !_isEqual(baseTheme.htmlReviews, templateEmailEditor?.reviewHTML)
                    ) {
                        saveThemeContent = true;
                    }
                } else {
                    if (
                        !_isEqual(email?.baseContent, templateEmailEditor.baseHTML) ||
                        !_isEqual(email?.productContent, templateEmailEditor?.productHTML) ||
                        !_isEqual(email?.reviewContent, templateEmailEditor?.reviewHTML)
                    ) {
                        saveThemeContent = true;
                    }
                }
            }

            let variableLocale = DEFAULT_LOCALE_REPURCHASE_TEMPLATE;

            if (subToolId === SubToolIdEnum.CartRecovery) {
                variableLocale = DEFAULT_LOCALE_CART_RECOVERY_TEMPLATE;
            } else if (subToolId === SubToolIdEnum.BankSlipReminder) {
                variableLocale = DEFAULT_LOCALE_BANK_SLIP_REMINDER_TEMPLATE;
            }

            const variables = translator({
                locale: variableLocale,
                locales: router.locales,
                defaultLocale: router.defaultLocale
            }).variables;

            if (email && formData.templateEmailEditorEnabled) {
                const hadPreviousContent =
                    (!_isNil(email?.baseContent) && !_isEmpty(email?.baseContent)) ||
                    (!_isNil(email?.productContent) && !_isEmpty(email?.productContent)) ||
                    (!_isNil(email?.reserveProductContent) && !_isEmpty(email?.reserveProductContent)) ||
                    (!_isNil(email?.reviewContent) && !_isEmpty(email?.reviewContent));
                saveThemeContent ||= hadPreviousContent;

                if (
                    (!_isNil(templateEmailEditor.baseHTML) && !_isEmpty(templateEmailEditor.baseHTML)) ||
                    (!_isNil(templateEmailEditor.productHTML) && !_isEmpty(templateEmailEditor.productHTML)) ||
                    (!_isNil(templateEmailEditor.reserveProductHTML) &&
                        !_isEmpty(templateEmailEditor.reserveProductHTML)) ||
                    (!_isNil(templateEmailEditor.subject) && !_isEmpty(templateEmailEditor.subject)) ||
                    (!_isNil(templateEmailEditor.reviewHTML) && !_isEmpty(templateEmailEditor.reviewHTML))
                ) {
                    templateConfiguration.push({
                        baseThemeId: baseTheme?.id ?? null,
                        sendingTechnology: sendingTechnologies.email.sendingTechnology,
                        isActive: sendingTechnologies.email.isActive,
                        subject: translateAllTemplateVariable(
                            templateEmailEditor.subject,
                            variables,
                            subToolKeyIdentifier
                        ),
                        reserveProductContent: templateEmailEditor.reserveProductHTML,
                        baseContent: saveThemeContent
                            ? translateAllTemplateVariable(
                                  templateEmailEditor.baseHTML,
                                  variables,
                                  subToolKeyIdentifier
                              )
                            : '',
                        productContent: saveThemeContent
                            ? translateAllTemplateVariable(
                                  templateEmailEditor.productHTML,
                                  variables,
                                  subToolKeyIdentifier
                              )
                            : null,
                        reviewContent: saveThemeContent
                            ? translateAllTemplateVariable(
                                  templateEmailEditor.reviewHTML,
                                  variables,
                                  subToolKeyIdentifier
                              )
                            : null,
                        recommendationContent: templateEmailEditor.recommendationHTML
                    });
                }
            }

            if (smsMessage && formData.smsEnabled) {
                const smsBase = translateAllTemplateVariable(smsMessage, variables, subToolKeyIdentifier);
                templateConfiguration.push({
                    isActive: sendingTechnologies.sms.isActive,
                    sendingTechnology: SendingTechnologyTypeEnum.SMS,
                    baseContent: smsBase,
                    subject: ''
                });
            }

            if (whatsappMessage && formData.whatsappEnabled) {
                const whatsAppTemplate = whatsAppTemplates.find((template) => template.id === whatsappMessage);
                if (whatsAppTemplate) {
                    templateConfiguration.push({
                        isActive: sendingTechnologies.whatsapp.isActive,
                        sendingTechnology: SendingTechnologyTypeEnum.WhatsApp,
                        baseContent: whatsAppTemplate.structure.body.text,
                        subject: '',
                        whatsAppTemplateId: whatsAppTemplate.id
                    });
                }
            }

            if (pushNotification && formData.pushEnabled) {
                const pushBodyContent = translateAllTemplateVariable(
                    pushNotification.body,
                    variables,
                    subToolKeyIdentifier
                );

                try {
                    const file = new FormData(event.target as HTMLFormElement).get('pushNotification.image');
                    const imageFormData = new FormData();
                    imageFormData.set('image', file as File);
                    pushNotification.image = (await SavePushContentImage(imageFormData)).data;
                } catch (_error) {
                    pushNotification.image = null;
                    captureException(_error);
                }

                if (pushNotification.image === null) {
                    pushNotification.image = pushData.pushNotificationContent?.image ?? null;
                }

                const pushNotificationActionViewOnList: { [key: string]: PushActionContent[] } = {
                    click: [],
                    pushOneButton: [
                        {
                            title: formData.pushNotification.oneButton?.text,
                            action: 'left',
                            type: 'button',
                            redirectUrl: formData.pushNotification.oneButton?.link
                        }
                    ],
                    pushTwoButtons: [
                        {
                            title: formData.pushNotification.twoButtons?.left?.text,
                            action: 'left',
                            type: 'button',
                            redirectUrl: formData.pushNotification.twoButtons?.left?.link
                        },
                        {
                            title: formData.pushNotification.twoButtons?.right?.text,
                            action: 'right',
                            type: 'button',
                            redirectUrl: formData.pushNotification.twoButtons?.right?.link
                        }
                    ]
                };

                const pushnotificationContent: PushNotificationContent = {
                    title: pushNotification.title,
                    body: pushBodyContent,
                    image: pushNotification.image,
                    icon: pushIcon ?? pushNotification.icon,
                    redirectUrl: formData.pushNotification.actionViewOn === 'click' ? pushNotification.redirectUrl : '',
                    actions: pushNotificationActionViewOnList[formData.pushNotification.actionViewOn]
                };

                templateConfiguration.push({
                    isActive: sendingTechnologies.push.isActive,
                    sendingTechnology: SendingTechnologyTypeEnum.Push,
                    baseContent: JSON.stringify(pushnotificationContent),
                    subject: ''
                });
            }

            if (!templateConfiguration.some((item) => item.isActive)) {
                warning(String(warnings.allTemplatesInactive));
                setIsLoading(false);
                return;
            }

            try {
                let intervalModifier = 1;
                if (
                    subToolId === SubToolIdEnum.Repurchase &&
                    stepConfig.frequencyType === SubToolsEventTriggers.BeforeRepurchaseDeadline
                ) {
                    intervalModifier = -1;
                }

                const intervalrange: MessageSequenceStepRange =
                    MessageSequenceStepRange[
                        SubToolTemplateConfigurationFrequencyRange[
                            stepConfig.timeUnit
                        ] as keyof typeof MessageSequenceStepRange
                    ];

                if (subToolId === SubToolIdEnum.NavigationTrigger && !sequenceId) {
                    if (onSubToolSaved) {
                        const messageSequenceConfiguration: NavigationTriggerRuleConfiguration = await onSubToolSaved(
                            formData
                        );

                        if (!messageSequenceConfiguration) {
                            setIsLoading(false);
                            return;
                        }

                        identifier = messageSequenceConfiguration.id;
                    }
                }

                let frequencyType: SubToolsEventTriggers;
                if (subToolId === SubToolIdEnum.BankSlipReminder) {
                    if (stepConfig.frequencyType === SubToolsEventTriggers.BeforeExpiration) {
                        frequencyType = SubToolsEventTriggers.AfterAbandonment;
                    } else {
                        frequencyType = SubToolsEventTriggers.AfterRepurchaseDeadline;
                    }
                }
                let messageSequenceName = messageSequenceStep.messageSequence?.name ?? '';

                if (subToolId === SubToolIdEnum.CustomTrigger) {
                    messageSequenceName = formData.customTriggerName;
                } else if (subToolId === SubToolIdEnum.Repurchase) {
                    messageSequenceName = product?.name ?? String(identifier);
                } else if (subToolId === SubToolIdEnum.NavigationTrigger) {
                    const treeList = convertTreeToList<NavigationTriggerRule>(formData?.rulesTree);
                    const tree = CreateRulesTree(treeList);

                    messageSequenceName = CreateRuleName(tree[0], navigationTriggerRuleLogicalOperator);
                }

                if (messageSequenceStep?.id) {
                    const payload: MessageSequenceStepUpdate = {
                        id: messageSequenceStep.id,
                        intervalMinutes:
                            convertSendingPeriodToMinutes(stepConfig.timeUnit, stepConfig.interval) * intervalModifier,
                        intervalRange: intervalrange,
                        name: stepConfig.name,
                        templates: templateConfiguration,
                        frequencyType,
                        trackAnalytics
                    };
                    await UpdateMessageSequenceStepAsync(subToolPath, payload);

                    if (subToolId === SubToolIdEnum.CustomTrigger) {
                        await UpdateCustomTriggerAsync({
                            messageSequenceId: messageSequenceStep.messageSequence.id,
                            name: messageSequenceName
                        });
                    } else if (subToolId === SubToolIdEnum.NavigationTrigger) {
                        await UpdateMessageSequenceNameAsync({
                            messageSequenceId: messageSequenceStep.messageSequence.id,
                            name: messageSequenceName
                        });
                    }
                } else if (messageSequenceStep.messageSequence?.id || sequenceId) {
                    // create step of an existing message sequence
                    const payload: MessageSequenceStepCreate = {
                        messageSequenceId: messageSequenceStep.messageSequence?.id || Number(sequenceId),
                        messageSequenceName: messageSequenceName,
                        messageSequenceType:
                            messageSequenceStep.messageSequence?.type || Number(type) || MessageSequenceType.General,
                        messageSequenceIdentifier: identifier?.toString() ?? '',
                        trackAnalytics,
                        intervalMinutes:
                            convertSendingPeriodToMinutes(stepConfig.timeUnit, stepConfig.interval) * intervalModifier,
                        intervalRange: intervalrange,
                        name: stepConfig.name,
                        templates: templateConfiguration,
                        frequencyType
                    };
                    await CreateMessageSequenceStepAsync(subToolPath, payload);
                } else {
                    // create step and a new message sequence
                    const payload: MessageSequenceStepCreate = {
                        // TODO @reueljonathan 2023-10-10: Read from name input to be inserted in the screen
                        messageSequenceId: null,
                        messageSequenceName: messageSequenceName,
                        messageSequenceType:
                            messageSequenceStep.messageSequence?.type || Number(type) || MessageSequenceType.General,
                        messageSequenceIdentifier: identifier?.toString() ?? '',
                        trackAnalytics,
                        intervalMinutes:
                            convertSendingPeriodToMinutes(stepConfig.timeUnit, stepConfig.interval) * intervalModifier,
                        intervalRange: intervalrange,
                        name: stepConfig.name,
                        templates: templateConfiguration,
                        frequencyType
                    };
                    await CreateMessageSequenceStepAsync(subToolPath, payload);
                }

                await mutate(() =>
                    serializeSWRKey({
                        url: subToolsFunctions[subToolId as SubToolIdEnum].getMessageSequencesUrl,
                        subToolId,
                        currentPage: 1
                    } as GetMessageSequencesCacheKey)
                );

                success(String(successes.successfullyChangedTemplates));
                redirectToMyAutomationsPage();
                setIsLoading(false);
            } catch (_error) {
                isDevelopment() && console.error(_error);
                if (_error instanceof EnviouApiResponseError) {
                    error(_error.getFirstError('createOrUpdateMessageSequenceStatusError').message);
                } else {
                    error(String(errors.failedChangingTemplates.title), (_error as Error)?.message);
                }
                captureException(_error);
                setIsLoading(false);
            }
        } catch (exception) {
            setIsLoading(false);
            if (exception instanceof ValidationError) {
                const errorMessages = {};
                exception.inner.forEach((error) => (errorMessages[error.path] = error.message));
                formRef.current.setErrors(errorMessages);

                const firstErroredFieldName = Object.keys(formRef.current.getErrors())[0];
                setErroredFieldName(firstErroredFieldName);

                isDevelopment() && console.log(errorMessages);
            } else {
                captureException(exception);
                error(String(errors.failedChangingTemplates.title), (exception as Error)?.message);
            }
        }
    };

    useEffect(() => {
        if (erroredFieldName) {
            let erroredField = formRef.current.getFieldRef(erroredFieldName);
            if (isInvisible(erroredField)) {
                erroredField = document.getElementsByName(erroredField.getAttribute('name'))[0];
            }

            const baseHtmlField = 'templateEmailEditor.baseHTML';
            if (erroredFieldName === baseHtmlField) {
                erroredField = document.getElementById(baseHtmlField)?.parentElement;
            }

            erroredField?.focus && erroredField.focus();

            scrollTo(erroredField);
            setErroredFieldName(null);
        }
    }, [erroredFieldName, formRef]);

    const pushNotificificationInitalData = useMemo(() => {
        const getActionViewType = (step: MessageSequenceStepTemplate) => {
            if (!step.pushNotificationContent?.actions?.length) {
                return {
                    type: 'click',
                    action: {
                        left: {
                            text: null,
                            link: step.pushNotificationContent?.redirectUrl
                        },
                        right: null
                    }
                };
            }

            if (step.pushNotificationContent?.actions?.length === 1) {
                return {
                    type: 'pushOneButton',
                    action: {
                        left: {
                            text: step.pushNotificationContent.actions[0].title,
                            link: step.pushNotificationContent.actions[0].redirectUrl
                        },
                        right: null
                    }
                };
            }

            return {
                type: 'pushTwoButtons',
                action: {
                    left: {
                        text: step.pushNotificationContent.actions[0].title,
                        link: step.pushNotificationContent.actions[0].redirectUrl
                    },
                    right: {
                        text: step.pushNotificationContent.actions[1].title,
                        link: step.pushNotificationContent.actions[1].redirectUrl
                    }
                }
            };
        };

        const actionViewType = getActionViewType(pushData);

        return {
            actionViewOn: actionViewType.type as ActionViewType,
            body: pushData.pushNotificationContent?.body,
            title: pushData.pushNotificationContent?.title,
            icon: pushIcon ?? pushData.pushNotificationContent?.icon,
            image: pushData.pushNotificationContent?.image,
            redirectUrl: (actionViewType.type === 'click' && actionViewType.action.left.link) || null,
            oneButton: (actionViewType.type === 'pushOneButton' && actionViewType.action.left) || null,
            twoButtons: (actionViewType.type === 'pushTwoButtons' && actionViewType.action) || null
        } as PushEditorFormData;
    }, [pushData, pushIcon]);

    const sendingPeriod = minutesToDays(messageSequenceStep?.intervalMinutes);

    let { identifier } = query;
    const { type, sequenceId } = query;

    const baseThemeId = smsData?.baseThemeId ?? email?.baseThemeId ?? null;

    const emailEditor: EmailEditorFormData = {
        baseHTML: email?.baseContent ?? '',
        subject: email?.subject ?? '',
        productHTML: email?.productContent ?? '',
        recommendationHTML: email?.recommendationContent ?? '',
        reserveProductHTML: email?.reserveProductContent ?? '',
        reviewHTML: email?.reviewContent ?? ''
    };

    const handleConfirmCancel = () => {
        show(null, null, {
            content: (args: Alert) => (
                <ConfirmCancelationDialog
                    {...args}
                    title={String(questions.cancelTemplateChange)}
                    onYesClick={() => {
                        redirectToMyAutomationsPage();
                        args.options.onOpenChange(false);
                    }}
                    onToBackClick={() => args.options.onOpenChange(false)}
                />
            )
        });
    };

    const handleConfirmStepDelete = () => {
        show(null, null, {
            content: (args: Alert) => (
                <ConfirmDeletionDialog
                    {...args}
                    title={String(questions.deleteTemplate)}
                    onDeleteClick={async () => {
                        await handleDeleteButton();
                        args.options.onOpenChange(false);
                    }}
                    onToBackClick={() => args.options.onOpenChange(false)}
                />
            )
        });
    };

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmitForm}
            initialData={{
                baseThemeId,
                customTriggerName: messageSequenceStep?.messageSequence?.name,
                stepName: messageSequenceStep?.name,
                sendingPeriod: String(sendingPeriod),
                smsEnabled: smsData?.isActive,
                smsMessage: smsData?.baseContent,
                whatsappEnabled: whatsappData?.isActive,
                whatsappMessage: whatsappData?.baseContent,
                templateEmailEditor: emailEditor,
                sendingTechnologies: sendingTechnologies,
                type: messageSequenceStep?.messageSequence?.type ?? SubToolTemplateConfigurationTypeEnum.General,
                identifier: messageSequenceStep?.messageSequence?.identifier ?? identifier ?? '',
                trackAnalytics: messageSequenceStep?.trackAnalytics ?? null,
                templateEmailEditorEnabled: email?.isActive || false,
                pushNotification: pushNotificificationInitalData
            }}>
            <SubToolConfigurationTemplateStyled>
                <PageTitle>{translatedSubToolName}</PageTitle>
                {subToolConfiguratorElement}
                {subToolId === SubToolIdEnum.CustomTrigger && <CustomTriggerConfigCard />}
                <FrequencySelector
                    subtoolKey={subToolKeyIdentifier}
                    subToolColor={subToolKey}
                    stepName={stepConfig.name}
                    interval={stepConfig.interval}
                    frequencyType={stepConfig.frequencyType}
                    timeUnit={stepConfig.timeUnit as SubToolTemplateConfigurationFrequencyRange}
                    onConfigChange={handleConfigurationChange}
                    subToolsEventTriggers={subToolsEventTriggers}
                />
                <SMSTemplateEditor
                    formRef={formRef}
                    initialValue={smsData?.baseContent ?? ''}
                    enableTechnology={sendingTechnologies.sms.isActive}
                    onChangeEnableTechnology={handleChangeSendingTechnology}
                    customizationVariables={smsTemplateVariables}
                    subToolKeyIdentifier={subToolKeyIdentifier}
                    subToolColor={subToolKey}
                    isReduced
                />
                <WhatsAppTemplateEditor
                    formRef={formRef}
                    initialValue={whatsappData?.whatsAppTemplateId ?? undefined}
                    customizationVariables={whatsappTemplateVariables}
                    enableTechnology={sendingTechnologies.whatsapp.isActive}
                    onChangeEnableTechnology={handleChangeSendingTechnology}
                    subToolKeyIdentifier={subToolKeyIdentifier}
                    subToolColor={subToolKey}
                    subToolId={subToolId}
                    isReduced
                />

                <PushTemplateEditor
                    formRef={formRef}
                    initialData={pushData?.pushNotificationContent}
                    customizationVariables={pushTemplateVariables}
                    enableTechnology={sendingTechnologies.push.isActive}
                    onChangeEnableTechnology={handleChangeSendingTechnology}
                    subToolKeyIdentifier={subToolKeyIdentifier}
                    subToolColor={subToolKey}
                    isReduced
                />

                <CreateOrUpdateEmailTemplate
                    editorFormGroupIdentifier='templateEmailEditor'
                    customizationVariables={emailTemplateVariables}
                    initialData={emailEditor}
                    formRef={formRef}
                    subToolKeyIdentifier={subToolKeyIdentifier}
                    baseThemeId={baseThemeId}
                    onDataChange={() => setThemeHasBeenChanged(true)}
                    enableTechnology={sendingTechnologies.email.isActive}
                    onChangeEnableTechnology={(value) =>
                        handleChangeSendingTechnology(value, SendingTechnologyTypeEnum.Email)
                    }
                    subToolColor={subToolKey}
                    subToolId={subToolId}
                />
                <TagsAnalyticsCard subToolColor={subToolKey} />
                <SubToolConfigurationTemplateButtonGroupStyled>
                    {messageSequenceStep?.id && (
                        <Button color='red' isOutlined onClick={handleConfirmStepDelete}>
                            {String(buttons.deleteTemplate)}
                        </Button>
                    )}
                    <div>
                        <Button color='purple' isOutlined onClick={handleConfirmCancel}>
                            {String(buttons.cancel)}
                        </Button>
                        <Button color='purple' type='submit' inLoading={isLoading}>
                            {String(buttons.save)}
                        </Button>
                    </div>
                </SubToolConfigurationTemplateButtonGroupStyled>
            </SubToolConfigurationTemplateStyled>
        </Form>
    );
};
