export type CustomizationVariable = {
    name: CustomizationVariableGroupName;
    variables: Array<string>;
};

export enum CustomizationVariableGroupName {
    subjectData = 'subjectData',
    consumerData = 'consumerData',
    storeData = 'storeData',
    productData = 'productData',
    reviewData = 'reviewData',
    cashbackData = 'cashbackData',
    smsData = 'smsData',
    navigationProductData = 'navigationProductData'
}
