import { useMemo } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import { Shimmer, Text } from '@components';
import { useFetch, useNumberFunctions, useTranslator } from '@hooks';
import { GeneralDetailedStatistics } from '@models';
import { useExpandableGroup } from '@providers';
import { captureException } from '@sentry/nextjs';
import { getAutomationStatisticsSummaryIcons } from '~/components/elements';
import {
    GetSendingTechnologyDetailedStatistics,
    getSendingTechnologyDetailedStatisticsUrl
} from '~/services/statisticsApiService';
import {
    fakeSendingTechnologyTrackStatistics,
    GetDetailedGeneralStatisticsBySendingTechnology,
    StatisticType
} from '~/services/statisticsService';
import { SendingTechnologiesContent, SendingTechnologiesStyled, SendingTechnologyStyled } from './styles';

const MINUTES_TO_REQUERY_DATA = 1;

export const SendingTechonologiesContent = ({ subscriptionSendingTechnologies = [] }) => {
    const [, sent, opening, click, sale] = useMemo(() => getAutomationStatisticsSummaryIcons(), []);

    const { expanded, triggerId, contentId, data } = useExpandableGroup();
    const {
        common: { sendingTechnology: sendingTecnologyTranslation },
        pages: {
            automationStatistics: {
                summaryStatistics,
                statisticsPerCustomerSection: { sendingTechnologyContent }
            }
        }
    } = useTranslator();

    const { formatCurrency, formatNumber } = useNumberFunctions();
    const statistic = data as GeneralDetailedStatistics;

    const { data: sendingTechnologies, isLoading } = useFetch(
        expanded && {
            url: getSendingTechnologyDetailedStatisticsUrl(statistic.transactionItemId),
            transactionItemId: statistic.transactionItemId
        },
        async ({ transactionItemId }, signal) => {
            const response = await GetSendingTechnologyDetailedStatistics(transactionItemId, signal);

            return GetDetailedGeneralStatisticsBySendingTechnology(
                response.data,
                subscriptionSendingTechnologies,
                sendingTecnologyTranslation
            );
        },
        {
            onError: (error) => captureException(error),
            keepPreviousData: true,
            dedupingInterval: minutesToMilliseconds(MINUTES_TO_REQUERY_DATA),
            fallbackData: GetDetailedGeneralStatisticsBySendingTechnology(
                fakeSendingTechnologyTrackStatistics,
                subscriptionSendingTechnologies,
                sendingTecnologyTranslation
            )
        }
    );

    const ignoreTypes: Array<StatisticType> = ['rejection', 'schedule'];

    return (
        <SendingTechnologiesStyled isExpanded={expanded} role='region' aria-labelledby={triggerId} id={contentId}>
            <SendingTechnologiesContent>
                <div>
                    <SendingTechnologyStyled>
                        <Text as='strong' size='sm'>
                            {String(sendingTechnologyContent.sendingTechnology)}
                        </Text>
                        <Text as='span' lineHeight='none' title={String(summaryStatistics.sent.name)}>
                            {sent.icon}
                        </Text>
                        <Text as='span' lineHeight='none' title={String(summaryStatistics.opening.name)}>
                            {opening.icon}
                        </Text>
                        <Text as='span' lineHeight='none' title={String(summaryStatistics.click.name)}>
                            {click.icon}
                        </Text>
                        <Text as='span' lineHeight='none' title={String(summaryStatistics.sale.name)}>
                            {sale.icon}
                        </Text>
                    </SendingTechnologyStyled>
                    <Shimmer isActive={isLoading}>
                        {sendingTechnologies?.map((item) => (
                            <SendingTechnologyStyled key={item.sendingTechnologyType} data-shimmer>
                                <Text as='span' size='sm'>
                                    {item.sendingTechnologyName}
                                </Text>
                                {item?.data
                                    ?.filter((statistic) => !ignoreTypes.includes(statistic.type))
                                    ?.map((statistic) => (
                                        <Text as='strong' size='sm' key={statistic.type}>
                                            {statistic.type === 'sale'
                                                ? formatCurrency(statistic.amount ?? 0)
                                                : formatNumber(statistic.count)}
                                        </Text>
                                    ))}
                            </SendingTechnologyStyled>
                        ))}
                    </Shimmer>
                </div>
            </SendingTechnologiesContent>
        </SendingTechnologiesStyled>
    );
};
