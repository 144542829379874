import isEmpty from 'lodash/isEmpty';
import { GetServerSidePropsContext, NextPageContext, NextApiResponse, Redirect } from 'next';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { User } from '@models';
import { translateRoute, isExpired, isProduction, LAST_DATE_QUERY_SUBSCRIPTION_STATUS_DATE_COOKIENAME } from '@utils';
import { Themes } from '~/styles/styled';
import {
    SignInRequest,
    SignInResponseData,
    GetAuthenticatedUserRequest,
    SetDefaultHeader,
    RecoverPasswordRequest
} from './apiService';

const tokenCookieName = process.env.NEXT_PUBLIC_TOKEN_COOKIE_NAME;
const userCookieName = process.env.NEXT_PUBLIC_USER_COOKIE_NAME;

type SignInData = {
    username: string;
    password: string;
};

export const SignIn = async ({ username, password }: SignInData): Promise<SignInResponseData> => {
    const axiosResponse = await SignInRequest(username, password);

    if (axiosResponse.data) {
        SetDefaultHeader({ Authorization: `Bearer ${axiosResponse.data.token}` });

        return axiosResponse.data;
    }
};

export const GetUserIsAuthenticated = async (context: GetServerSidePropsContext) => {
    let userIsAuthenticated = false;
    let user: User = null;
    const { [tokenCookieName]: tokenCookie, [userCookieName]: userCookie, theme } = parseCookies(context);

    const token: string = tokenCookie ? JSON.parse(tokenCookie) : null;

    const signOutRedirect: Redirect = {
        destination: `${translateRoute('/signIn', context.locale)}?redirectTo=${context.req.url}`,
        permanent: false
    };

    /* Note: this is not an actual "token validation", as the real validation happens on server-side per request.
       Instead, this is just a convenience for the user to ensure their token is not expired so they will not have
       to manually sign in again.

       If an attacker were to change this code to consider their token to be valid, the client would not redirect them
       to the sign in page, but the back-end would still block each incoming request. */
    const isTokenValid = !!token && !isExpired(token);

    if (!isTokenValid) {
        return {
            userIsAuthenticated,
            user,
            token,
            redirect: signOutRedirect
        };
    }

    SetDefaultHeader({ Authorization: `Bearer ${token}`, 'Accept-Language': context.locale });

    if (userCookie) {
        user = JSON.parse(userCookie) as User;
    } else {
        const result = await GetAuthenticatedUserRequest();
        user = result.data;

        setCookie(context, userCookieName, JSON.stringify(user), {
            secure: isProduction(),
            sameSite: 'strict',
            path: '/'
        });
    }

    userIsAuthenticated = !!user && !isEmpty(user) && isTokenValid;

    user.theme = theme as Themes;
    return { userIsAuthenticated, user, token, redirect: signOutRedirect };
};

export const GetAuthenticatedUser = async (token?: string) => {
    token && SetDefaultHeader({ Authorization: `Bearer ${token}` });

    return GetAuthenticatedUserRequest();
};

export const SignOut = (context?: Pick<NextPageContext, 'res'> | { res: NextApiResponse } | null | undefined) => {
    destroyCookie(context, tokenCookieName);
    destroyCookie(context, userCookieName);
    destroyCookie(context, LAST_DATE_QUERY_SUBSCRIPTION_STATUS_DATE_COOKIENAME);
};

export const RecoverPassword = async (username: string) => {
    const axiosResponse = await RecoverPasswordRequest(username);

    return axiosResponse.data;
};
