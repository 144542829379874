import { useFetch, useReplace, useTranslatedRoute, useTranslator } from '@hooks';
import { SendingTechnologyTypeEnum, SubToolIdEnum } from '@models';
import { AlertOptions } from '@providers';
import { routesName } from '~/locales/route';
import { GetSubToolSendingTechnologyTypesFromStoreSubscriptionAsync } from '~/services/subToolApiService';
import { getSendingTechnologyIcons, Text, Link, Shimmer } from '../';
import { Dialog, DialogHeading, DialogClose } from '../dialog';
import { DialogContentStyled, SendingTechnologyItemStyled } from './styles';

type SendingTechnologySettingModalProps = {
    options: AlertOptions;
};

type SendingTechnologySettingData = {
    type: SendingTechnologyTypeEnum;
    name: string;
    link?: {
        href: string;
        as: string;
    };
    userHasHiredTechnology: boolean;
    disabled: boolean;
};

export const SendingTechnologySettingModal = ({ options }: SendingTechnologySettingModalProps) => {
    const icons = getSendingTechnologyIcons();
    const {
        sendingTechnologySettingModal: { title, sendingTechnologies }
    } = useTranslator();
    const translateRoute = useTranslatedRoute();
    const replace = useReplace();

    const sendingTechnologiesSettings: Array<SendingTechnologySettingData> = [
        {
            type: SendingTechnologyTypeEnum.Push,
            name: String(sendingTechnologies.push),
            link: {
                href: replace(
                    routesName.sendingTechnologySettings.setting,
                    { sendingTechnology: 'push' },
                    { start: '{', end: '}' }
                ),
                as: translateRoute(routesName.sendingTechnologySettings.setting, { sendingTechnology: 'push' })
            },
            userHasHiredTechnology: false,
            disabled: true
        },
        {
            type: SendingTechnologyTypeEnum.WhatsApp,
            name: String(sendingTechnologies.whatsapp),
            link: null,
            userHasHiredTechnology: false,
            disabled: true
        },
        {
            type: SendingTechnologyTypeEnum.Email,
            name: String(sendingTechnologies.email),
            link: null,
            userHasHiredTechnology: false,
            disabled: true
        },
        {
            type: SendingTechnologyTypeEnum.SMS,
            name: String(sendingTechnologies.sms),
            link: null,
            userHasHiredTechnology: false,
            disabled: true
        }
    ];

    const { data, isLoading } = useFetch(
        'sendingTechnologySettings',
        async () => {
            const response = await GetSubToolSendingTechnologyTypesFromStoreSubscriptionAsync(SubToolIdEnum.Repurchase);

            return sendingTechnologiesSettings.map((item) => {
                if (item.type === SendingTechnologyTypeEnum.Push) {
                    return {
                        ...item,
                        userHasHiredTechnology: true,
                        disabled: false
                    };
                }

                const userHasHiredTechnology = !!response.data?.find((type) => type === item.type);

                return {
                    ...item,
                    userHasHiredTechnology,
                    disabled: !item?.link || !userHasHiredTechnology
                };
            });
        },
        {
            keepPreviousData: true,
            fallbackData: sendingTechnologiesSettings
        }
    );

    const handleCloseModal = (technology: SendingTechnologySettingData) => {
        if (!technology.disabled) {
            options.onOpenChange(false);
        }
    };

    return (
        <Dialog {...options}>
            <DialogContentStyled>
                <DialogHeading>{String(title)}</DialogHeading>
                <ul>
                    <Shimmer isActive={isLoading}>
                        {data?.map((technology) => (
                            <SendingTechnologyItemStyled
                                key={technology.type}
                                disabled={technology.disabled}
                                data-shimmer>
                                <Link {...technology?.link} showLink={!technology.disabled}>
                                    <div onClick={() => handleCloseModal(technology)}>
                                        <i>{icons.find((icon) => icon.type === technology.type).icon}</i>
                                        <Text size='lg' fontWeight='semiBold'>
                                            {technology.name}
                                        </Text>
                                    </div>
                                </Link>
                            </SendingTechnologyItemStyled>
                        ))}
                    </Shimmer>
                </ul>
                <DialogClose />
            </DialogContentStyled>
        </Dialog>
    );
};
